import { render, staticRenderFns } from "./NewUpdate.vue?vue&type=template&id=67d67c02&scoped=true"
import script from "./NewUpdate.vue?vue&type=script&lang=js"
export * from "./NewUpdate.vue?vue&type=script&lang=js"
import style0 from "./NewUpdate.vue?vue&type=style&index=0&id=67d67c02&prod&scoped=true&lang=scss"
import style1 from "./NewUpdate.vue?vue&type=style&index=1&id=67d67c02&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67d67c02",
  null
  
)

export default component.exports