<template>
  <div class="wrapper-content-items" :class="darkMode ? 'dark' : 'light'">
    <div class="title-section-content">
      <div id="myHeader" class="title-section">
        <h2>{{ $t("text_inicio_productos") }}</h2>
      </div>
    </div>
    <!-- <MultiplesProductsDialog :products="productsData" v-if="productsData" /> -->
    <!-- Main content -->
    <section class="p-5">
      <div
        :class="darkMode ? 'darkbg' : 'lightbg'"
        class="actions p-8 mb-6 wrapper-content-title"
      >
        <div class="imgsabsolute">
          <img
            loading="lazy"
            id="circles"
            src="../../../assets/iconosBannerHome/circles.png"
            alt
          />
          <img
            loading="lazy"
            id="piece"
            src="../../../assets/iconosBannerHome/piece.png"
            alt
          />
        </div>
        <!-- <div class="box_top">
          <div class="title-invetory">
            <h1 class="text-2xl">Inventario</h1>
          </div>
        </div> -->
        <div class="box_bottom">
          <div class="boxb_inputs">
            <el-input
              v-model="search"
              :placeholder="$t('text_ventas_buscarProducto')"
              :class="darkMode ? 'darks-input' : 'light-input'"
              type="search"
              autocomplete="off"
              autocorrect="off"
              class="inputs-idTienda"
              @input="buscar(search)"
            >
              <i slot="suffix" class="el-input__icon el-icon-search"></i>
            </el-input>
            <el-select
              slot="prepend"
              v-model="category"
              placeholder="Seleccionar categoría"
              class="inputs-idTienda"
              :class="darkMode ? 'darks-input' : 'light-input'"
              filterable
              clearable
              @change="changeCategory"
            >
              <el-option
                :label="$t('text_ventas_todos')"
                :value="0"
              ></el-option>
              <el-option
                v-for="(itemsCategory, index) in categoriesData"
                :key="`category${index}`"
                :label="itemsCategory.nombre_categoria_producto"
                :value="itemsCategory.id"
              ></el-option>
              <!-- <el-option
                label="Agotados"
                v-if="productsAgotados"
                :value="1"
              ></el-option> -->
            </el-select>
          </div>
          <div class="boxb_buttonActions">
            <el-button
              class="buttonActions exportar"
              @click="openMultiplesProducts"
            >
              {{ $t("text_ventas_exportar") }} /
              {{ $t("text_ventas_importar") }}
            </el-button>
            <a
              title="Abrir ayuda Komercia"
              class="ml-4 flex items-center border border-purple-komercia-100 rounded-lg py-2 px-3 text-purple-komercia-100 hover:border-purple-600 hover:text-purple-600"
              href="https://ayuda.komercia.co/komercia/categories/582/Inventario/11"
              target="_blank"
            >
              {{ $t("text_helpDesk_Aprende") }}
              <img
                loading="lazy"
                class="w-5 ml-2"
                src="@/assets/information.webp"
                alt="imgInformation"
              />
            </a>
          </div>
        </div>
      </div>
      <div class="actions_bottom">
        <div class="actionsa_buttons">
          <el-radio-group v-model="previewMode" size="medium">
            <el-radio-button :label="$t('text_ventas_ver_productos')">
              <!-- <Boxes /> -->
            </el-radio-button>
            <el-radio-button :label="$t('text_ventas_editar_productos')">
              <!-- <BurguerBars /> -->
            </el-radio-button>
          </el-radio-group>
        </div>
        <div>
          <!-- <div class="alertProductAgotado">
            <i class="el-icon-warning-outline"></i>
            <p>
              Tienes {{ productsAgotados.length }}
              {{
                productsAgotados.length === 1
                  ? 'producto agotado'
                  : 'productos agotados'
              }}
            </p>
          </div> -->
          <div class="flex">
            <span class="flex flex-col justify-center items-center p-1 mr-2">
              <strong class="text-2xl leading-5 text-purple-komercia-600">
                {{ infoPaginationProducts.total }}
              </strong>
              <span
                class="leading-4"
                :class="darkMode ? 'darktxt' : 'lighttxt'"
              >
                {{ $t("text_inicio_productos") }}
              </span>
            </span>
            <span class="flex flex-col justify-center items-center p-1">
              <strong class="text-2xl leading-5 text-red-600">
                {{ productsAgotados.length }}
              </strong>
              <span
                :class="darkMode ? 'darktxt' : 'lighttxt'"
                class="leading-4"
              >
                {{ $t("text_ventas_agotados") }}
              </span>
            </span>
          </div>
        </div>

        <!-- <div class="actionsb_buttons">
          <div class="productosText">
            <p><strong>Productos</strong></p>
            <div
              class="contentslider"
              v-if="infoPaginationProducts && infoPaginationProductsInactivos"
            >
              <span class="limiteproducto"
                >{{
                  infoPaginationProducts.total +
                    infoPaginationProductsInactivos.total
                }}
                usados de
                <p v-if="settingProducts.amount != 99999999">
                  {{ settingProducts.amount }}
                </p>
                <p v-else>ilimitado</p>
              </span>
              <input
                class="slider"
                type="range"
                min="0"
                :max="settingProducts.amount"
                :value="
                  infoPaginationProducts.total +
                    infoPaginationProductsInactivos.total
                "
                disabled
              />
            </div>
          </div>
          <a
            href="https://komercia.co/precios/"
            target="_blank"
            v-show="
              !isPremium &&
                (validateAmount || settingProducts.amount != 99999999)
            "
          >
            <el-button type="primary">Aumentar inventario</el-button>
          </a>
        </div> -->
      </div>
      <Grid
        v-if="
          previewMode === 'Ver productos' && (productsFilter || productsData)
        "
        :products-filter="productsFilter"
        :products-data="productsData"
        :validate-amount="validateAmount"
      />
      <List
        v-else-if="productsFilter"
        :products-filter="productsFilter"
        :products-data="productsData"
      />
    </section>
    <div class="wrapper-pagination">
      <el-pagination
        layout="prev, pager, next"
        :hide-on-single-page="true"
        :page-count="infoPaginationProducts.last_page"
        :current-page="previousPage"
        @current-change="currentChange"
      >
      </el-pagination>
    </div>
    <!-- /.content -->
  </div>
</template>
<script>
import { mapState } from "vuex";
import axios from "axios";
import Grid from "@/components/Products/Grid";
import List from "@/components/Products/List";
import debounce from "debounce";
// import BurguerBars from '../../../icons/burguerBars.vue'
// import Boxes from '../../../icons/boxes.vue'
// import MultiplesProductsDialog from '@/components/MultiplesProducts'
// import Fuse from 'fuse.js'
export default {
  name: "ListProducts",
  components: {
    Grid,
    List,
    // MultiplesProductsDialog,
    // BurguerBars,
    // Boxes,
  },
  data() {
    return {
      // filter: null,
      // currentPage: 1,
      width: 0,
      tabAction: "export",
      previewMode: "Ver productos",
      dialogVisible: false,
      search: "",
      category: 0,
      productsFilter: [],
      productosAgotados: [],
      options: {
        threshold: 0.6,
        location: 0,
        distance: 100,
        maxPatternLength: 10,
        minMatchCharLength: 6,
        keys: [
          "nombre",
          "categoria_producto.nombre_categoria_producto",
          "descripcion",
        ],
      },
    };
  },
  computed: {
    darkMode() {
      return this.$store.state.darkMode;
    },
    ...mapState([
      "storeData",
      "infoPaginationProducts",
      "infoPaginationProductsInactivos",
    ]),
    categoriesData() {
      return this.$store.state.categoriesData.filter(
        (category) => category.producto_count !== 0
      );
    },
    previousPage() {
      return this.$store.state.previousPage;
    },
    productsData() {
      return this.$store.getters.getActiveProducts;
    },
    // cantCombinaciones() {
    //   return this.$store.getters.numberOfCombinations
    // },
    productsAgotados() {
      return this.$store.getters.getSoldOutProducts;
    },
    settingProducts() {
      return this.$store.getters.settingProducts;
    },
    isPremium() {
      return [3, 9, 20, 21].indexOf(this.storeData.tipo) !== -1;
    },
    validateAmount() {
      if (
        this.$store.state.infoPaginationProducts &&
        this.$store.state.infoPaginationProductsInactivos
      ) {
        return (
          this.$store.state.infoPaginationProducts.total +
            this.$store.state.infoPaginationProductsInactivos.total <
          this.settingProducts.amount
        );
      } else {
        return false;
      }
    },
  },
  watch: {
    // previewMode(value) {
    //   localStorage.setItem('komercia_previweMode_products', value)
    // },
  },
  created() {
    this.currentChange(this.previousPage);
    this.$store.dispatch("GET_CATEGORIES");
    // this.getProductsAgotados()
    window.addEventListener("resize", this.handleResize);
    window.addEventListener("resize", this.vistaGridProductos);
    this.handleResize();
    this.vistaGridProductos();
    // window.onscroll = function() {
    //   var header = document.getElementById('myHeader')
    //   var sticky = header.offsetTop
    //   if (window.pageYOffset > sticky) {
    //     header.classList.add('sticky')
    //   } else {
    //     header.classList.remove('sticky')
    //   }
    // }
  },
  mounted() {
    // document.querySelector('.main-content').scrollTop = 0
    // this.previewMode =
    //   localStorage.getItem('komercia_previweMode_products') || 'grid'
    if (this.$route.query.category) {
      this.category = parseInt(this.$route.query.category);
    }
  },
  destroyed() {
    this.search = null;
    window.removeEventListener("resize", this.handleResize);
    window.removeEventListener("resize", this.vistaGridProductos);
  },
  methods: {
    currentChange(page) {
      if (this.search) {
        this.$store.dispatch("GET_PRODUCTS_BY_NAME", {
          search: this.search,
          page: page,
        });
        this.$store.commit("SET_PREVIOUS_PAGE", page);
      } else if (this.category != 0) {
        this.$store.dispatch("GET_PRODUCTS_BY_CATEGORY", {
          idCategory: this.category,
          page: page,
        });
        this.$store.commit("SET_PREVIOUS_PAGE", page);
      } else {
        this.$store.dispatch("GET_PRODUCTS", { page, status: 1, per_page: 24 });
        this.$store.commit("SET_PREVIOUS_PAGE", page);
      }
    },
    changeCategory() {
      if (this.validateAmount) {
        this.search = "";
        this.$store.commit("SET_PREVIOUS_PAGE", 1);
        if (this.category != 0) {
          this.$store.dispatch("GET_PRODUCTS_BY_CATEGORY", {
            idCategory: this.category,
            page: 1,
          });
        } else {
          this.$store.dispatch("GET_PRODUCTS", {
            page: 1,
            status: 1,
            per_page: 24,
          });
        }
      } else {
        this.$swal({
          title: "Mejora tu plan",
          html: '<p>Ya superaste la cantidad de productos de tu plan, actualízate al plan <strong class="swal-bold">Premium</strong></p>',
          showCloseButton: true,
          confirmButtonText: "Aceptar",
          backdrop: "rgba(14,47,90,0.6)",
          icon: "warning",
        }).then((r) => {
          if (r.isConfirmed) {
            this.$router.push({ name: "Membresia" });
          }
        });
      }
    },
    vistaGridProductos() {
      if (this.width < 768) {
        localStorage.setItem("komercia_previweMode_products", "Ver productos");
        this.previewMode = "Ver productos";
      }
    },
    handleResize() {
      this.width = window.innerWidth;
    },
    openMultiplesProducts() {
      this.$store.state.modalExport = true;
    },
    buscar: debounce(function (e) {
      this.search = e;
      this.$store.commit("SET_PREVIOUS_PAGE", 1);
      if (this.validateAmount) {
        this.$store.dispatch("GET_PRODUCTS_BY_NAME", { search: e, page: 1 });
      } else {
        this.$swal({
          title: "Mejora tu plan",
          html: '<p>Ya superaste la cantidad de productos de tu plan, actualízate al plan <strong class="swal-bold">Premium</strong></p>',
          showCloseButton: true,
          confirmButtonText: "Aceptar",
          backdrop: "rgba(14,47,90,0.6)",
          icon: "warning",
        }).then((r) => {
          if (r.isConfirmed) {
            this.$router.push({ name: "Membresia" });
          }
        });
      }
    }, 300),
    alert() {
      if (!this.validateAmount) {
        this.$swal({
          title: "Mejora tu plan",
          html: '<p>Has superado el límite de productos de tu plan, para agregar ilimitados productos pásate a <strong class="swal-bold">Premium</strong></p>',
          showCloseButton: true,
          confirmButtonText: "Aceptar",
          backdrop: "rgba(14,47,90,0.6)",
          icon: "warning",
        }).then((r) => {
          if (r.isConfirmed) {
            this.$router.push({ name: "Membresia" });
          }
        });
      } else {
        this.$router.push("/inventario/productos/nuevo");
      }
    },
    getProductsAgotados() {
      axios
        .get(
          `${this.$store.state.configKomercia.url}/api/admin/productos/paginados?page=1&per_page=50&status=0`,
          this.$store.state.configAxios
        )
        .then(() => {
          this.productsAgotados = response.data.productos.data;
          // console.log(response.data)
        });
    },
  },
};
</script>

<style scoped>
.inputs-idTienda >>> .el-input__inner {
  padding: 0px 15px;
  font-size: 13px;
  border-radius: 10px;
  border: solid 2px #f3f4f6;
  background-color: transparent;
  transition: none;
  max-height: 40px;
}
.darks-input >>> .el-input__inner {
  color: #f3f4f6;
}
.light-input >>> .el-input__inner {
  color: #1f2937;
}
.wrapper-content-items {
  width: 100%;
  padding: 10px 10px;
  border-radius: 12px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.09);
}
.wrapper-content-title {
  border-radius: 12px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.09);
}
.dark {
  background-color: #1b2836;
}
.light {
  @apply bg-white;
}
.darkbg {
  background-color: #283c51;
}
.lightbg {
  background-color: #fff;
}
.darktxt {
  color: #fff;
}
.lighttxt {
  color: rgb(26, 46, 59);
}
.el-tooltip .el-slider__button {
  border-color: var(--green);
}
.content-wrapper {
  background-color: var(--background);
}
.content {
  /* padding: 30px 200px 0px 200px; */
}
.newproduct {
  background-color: #00dd8d;
  color: var(--purple);
  border: 2px solid #00dd8d;
  font-weight: 600;
}
.limiteproducto {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 5px;
}
.contentslider {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.slider {
  -webkit-appearance: none;
  border-radius: 5px;
  height: 6px;
  background-color: #af9cff;
  margin: 5px 0;
}
.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 2px solid #00dd8d;
  background-color: #fff;
}
.slider::-moz-range-progress {
  background-color: var(--purple);
}
.productosText {
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  grid-gap: 10px;
  align-items: center;
}
.products_list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 20px;
  margin-top: 20px;
}
.actions {
  display: grid;
  align-items: center;
  border-radius: 7px;
  position: relative;
  overflow: hidden;
}
.imgsabsolute {
  position: absolute;
  width: 100%;
  top: 0;
}
.imgsabsolute #circles {
  width: 80px;
  height: 80px;
  top: -40px;
  right: 280px;
  position: absolute;
}
.imgsabsolute #piece {
  top: -49px;
  position: absolute;
  width: 130px;
  height: 130px;
  right: -52px;
}
.newBtnProduct {
  height: 40px;
  font-weight: 600;
  max-height: 40px;
  border-radius: 10px;
  @apply px-2 ml-3 text-purple-komercia-50 border-2 border-purple-komercia-50 hover:text-green-500 hover:border-green-500;
}
.buttonActions {
  color: white;
  background-color: var(--purple);
  font-weight: 600;
  max-height: 40px;
  border-radius: 10px;
}
.box_top {
  display: grid;
  grid-auto-flow: column;
}
.box_top h1 {
  color: var(--purple);
  margin-bottom: 8px;
}
.box_bottom {
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: center;
  grid-gap: 10px;
  justify-items: flex-end;
}
.boxb_inputs {
  width: 100% !important;
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-gap: 10px;
}
.boxb_buttonActions {
  display: grid;
  grid-auto-flow: column;
  align-items: flex-end;
}

.actions .el-input {
  width: 100%;
}
.actions-right {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 0px;
  justify-content: end;
  align-content: center;
  align-items: center;
}
.actions_bottom {
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  margin-bottom: 20px;
}
#burguer {
  fill: none;
  stroke: #cfcfcf;
  stroke-linecap: round;
  width: 20px;
  height: 20px;
}
#boxesbar {
  fill: none;
  stroke: #cfcfcf;
  stroke-miterlimit: 10;
  width: 20px;
  height: 20px;
}
.actionsb_buttons {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 20px;
}
.actionsa_buttons {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 20px;
  align-items: center;
}
.alertProductAgotado {
  @apply text-red-600;
  /* background-color: #f14b5a; */
  padding: 8px;
  border-radius: 7px;
  /* color: #fff; */
  font-size: 13px;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-gap: 5px;
}
.alertProductAgotado:hover {
  /* cursor: pointer; */
}
.upgrade {
  position: relative;
  width: 300px;
  height: 60px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: linear-gradient(135deg, #b72c47 0%, #f14b5a);
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
.upgrade:hover {
  animation: upgrade 2s linear;
  background: linear-gradient(135deg, #b72c47 100%, #f14b5a);
}
@keyframes upgrade {
  0% {
    background: linear-gradient(135deg, #b72c47 0%, #f14b5a);
  }
  5% {
    background: linear-gradient(135deg, #b72c47 5%, #f14b5a);
  }
  10% {
    background: linear-gradient(135deg, #b72c47 10%, #f14b5a);
  }
  15% {
    background: linear-gradient(135deg, #b72c47 15%, #f14b5a);
  }
  20% {
    background: linear-gradient(135deg, #b72c47 20%, #f14b5a);
  }
  25% {
    background: linear-gradient(135deg, #b72c47 25%, #f14b5a);
  }
  30% {
    background: linear-gradient(135deg, #b72c47 30%, #f14b5a);
  }
  35% {
    background: linear-gradient(135deg, #b72c47 35%, #f14b5a);
  }
  40% {
    background: linear-gradient(135deg, #b72c47 40%, #f14b5a);
  }
  45% {
    background: linear-gradient(135deg, #b72c47 45%, #f14b5a);
  }
  50% {
    background: linear-gradient(135deg, #b72c47 50%, #f14b5a);
  }
  55% {
    background: linear-gradient(135deg, #b72c47 55%, #f14b5a);
  }
  60% {
    background: linear-gradient(135deg, #b72c47 60%, #f14b5a);
  }
  65% {
    background: linear-gradient(135deg, #b72c47 65%, #f14b5a);
  }
  70% {
    background: linear-gradient(135deg, #b72c47 70%, #f14b5a);
  }
  75% {
    background: linear-gradient(135deg, #b72c47 75%, #f14b5a);
  }
  80% {
    background: linear-gradient(135deg, #b72c47 80%, #f14b5a);
  }
  85% {
    background: linear-gradient(135deg, #b72c47 85%, #f14b5a);
  }
  90% {
    background: linear-gradient(135deg, #b72c47 90%, #f14b5a);
  }
  95% {
    background: linear-gradient(135deg, #b72c47 95%, #f14b5a);
  }
  100% {
    background: linear-gradient(135deg, #b72c47 100%, #f14b5a);
  }
}
.upgrade img {
  position: absolute;
}
.upgrade p {
  width: 200px;
  color: #fff;
  margin: 0;
  text-align: center;
}
.upgrade i {
  color: #fff;
  font-size: 40px;
}
a.button {
  width: 220px;
  height: 45px;
  justify-content: center;
  justify-self: center;
}
a.button .upgrade {
  width: 100%;
  height: 100%;
}
a.button p {
  width: 130px;
}
a.button i {
  font-size: 30px;
}
.download_excel {
  cursor: pointer;
}
.download_excel img {
  width: 40px;
  height: 40px;
}
.new_product {
  min-height: 280px;
  display: grid;
  justify-content: center;
  justify-items: center;
  align-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.3s;
}
.new_product:hover {
  transform: scale(0.95);
}
.new_product img {
  max-width: 80px;
  max-height: 80px;
  object-fit: contain;
  margin-bottom: 20px;
}
.new_product h3 {
  color: #474d68;
  font-size: 13px;
}
.new_product p {
  text-align: center;
  font-size: 13px;
  padding: 0 30px;
}
.title-section-content {
  display: none;
}
.buttonActions-responsive {
  display: none;
  max-height: 40px;
  border-radius: 10px;
}
.wrapper-pagination {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 20px 0 20px;
}
@media (max-width: 1850px) {
  .box_bottom {
    /* grid-template-columns: 2fr 1fr; */
  }
  .content {
    /* padding: 30px 100px 0px 100px; */
  }
}
@media (max-width: 1650px) {
  .box_bottom {
    /* grid-template-columns: 2fr 1.5fr; */
  }
}
@media (max-width: 1350px) {
  .content {
    /* padding: 30px 70px 0px 70px; */
  }
  .actions {
    grid-gap: 10px;
  }
  .actions > div:first-child {
    align-self: flex-start;
  }
  .actions > div:last-child {
    align-self: flex-end;
  }
}
@media (max-width: 1000px) {
  .box_top {
    grid-auto-flow: row;
  }
  .actions-right {
    justify-content: start;
  }
}
@media (max-width: 767px) {
  .el-dialog {
    width: 85%;
  }
  .boxb_buttonActions {
    display: none;
  }
  .new-product {
    display: none;
  }
  .importar {
    display: none;
  }
  .buttonActions {
    display: none;
  }
  .buttonActions-responsive {
    display: flex;
    padding: 8px 20px;
    color: var(--purple);
    border: 1px solid var(--purple);
    margin-left: 0 !important;
    margin-right: 0;
  }
  .box_bottom {
    display: flex;
  }
  .actionsa_buttons {
    display: none;
  }
  .actions_bottom {
    /* display: flex; */
    display: none;
    justify-content: center;
  }
  .title-section-content {
    display: inline;
    min-height: 76px;
    width: 100%;
    margin-top: 15px;
  }
  .title-section {
    min-height: 60px;
    background-color: var(--purple);
    /* position: sticky; */
    top: 0;
    z-index: 999;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 15px;
    color: #ffffff;
  }
  .title-invetory h1 {
    display: none;
  }
  .title-sales {
    padding: 0px;
  }
  .box {
    background: transparent;
    box-shadow: unset;
  }
  .sticky {
    position: fixed;
    top: 0;
    width: 100%;
  }
  .actions {
    @apply p-4;
    overflow: unset;
    display: flex;
    grid-gap: 0px;
  }
  .imgsabsolute {
    display: none;
  }
  .box_bottom {
    width: 100%;
    display: flex;
  }
  .boxb_inputs {
    width: 100%;
    grid-template-columns: 1fr 1fr 0fr;
  }
  .product-item {
    display: flex;
    margin-bottom: 20px;
  }
  .product-item a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: unset;
  }
  .content {
    /* padding-top: 25px !important; */
  }
  .title-section h2 {
    font-size: 18px;
  }
}
@media (max-width: 435px) {
  .actions_bottom {
    justify-content: center;
  }
  .actionsb_buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .productosText {
    padding-bottom: 20px;
  }
}
@media (max-width: 381px) {
  .boxb_inputs {
    width: 100%;
    grid-template-columns: 1fr 1fr;
  }
  .box_bottom {
    flex-direction: column;
  }
  .boxb_buttonActions {
    display: none;
    padding-top: 10px;
    width: 100%;
    justify-content: flex-start;
  }
}
</style>
